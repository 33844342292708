import resume from '../assets/pdf/Lino_Silva_-_Resume.pdf';
import image from '../assets/jpeg/profile.jpeg';

export const headerData = {
    name: 'Lino Silva',
    title: "",
    description: "I'm an Engineer at heart, with over 12 years of experience on the tech / e-commerce / fashion / luxury / retail market.",
    image: image,
    resumePdf: resume
}
