import React from 'react'
import { Helmet } from 'react-helmet'

import { Navbar, Landing } from '../../components'
// import { Testimonials, About, Skills, Blog, Education, Experience, Projects, Services, Achievement, Footer } from '../../components'
import { headerData } from '../../data/headerData'

function Main() {
    return (
        <div>
            <Helmet>
                <title>{headerData.name} - Engineering Manager</title>
            </Helmet>

            <Navbar />        
            <Landing />
            {/* <About />
            <Experience />
            <Skills />
            <Education />
            <Projects />
            <Achievement />
            <Services /> 
            <Testimonials /> 
            <Blog />
            <Footer />*/}
        </div>
    )
}

export default Main
